import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import React, { Fragment } from 'react';
import Container from '../../components/layout/Container/Container';
import Group from '../../components/layout/Group/Group';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import Accordion from '../../components/ui/Accordion/Accordion';
import Avatar from '../../components/ui/Avatar/Avatar';
import Block from '../../components/ui/Cards/Block';
import PageBanner from '../../components/ui/PageBanners/PageBanner';
import Seo from '../../components/utils/Seo';
import { Direction } from '../../globals';
import './about.scss';

interface AboutProps {
    data: {
        authorsYaml: {
            authors: Author[];
        };
        file: FluidImage;
        faqYaml: {
            faq: QuestionAnswer[];
        };
    };
}
const About: React.FC<AboutProps> = ({ data }: AboutProps) => {
    return (
        <PageLayout>
            <Seo
                isPost={false}
                title="About"
                url="about/"
                description="About AI Summer. Our mission and who we are"
            />
            <PageBanner className="page-banner-withcircularimage">
                <Fragment>
                    <h1>Learn Deep Learning and Artificial Intelligence</h1>
                    <p>
                        At AI Summer you can learn everything about Deep
                        Learning from scratch. From primitive math concepts and
                        basic algorithms to design and development of state of
                        the art Computer Vision and Natural Language Processing
                        systems
                    </p>
                </Fragment>
                <Fragment>
                    <Img
                        fluid={data.file.childImageSharp.fluid}
                        alt="About AI Summer"
                    ></Img>
                </Fragment>
            </PageBanner>

            <PageSection>
                <Container>
                    <Group size={2}>
                        <Block
                            className="about-block"
                            direction={Direction.vertical}
                        >
                            <h2>About AI Summer</h2>

                            <p>
                                In the history of Artificial Intelligence (AI),
                                an <strong>AI winter</strong> was a period of
                                reduced research funding. Going back to 1974,
                                the field of AI has previously experienced
                                several seasonal hypes, followed by
                                disappointment and criticism, and as a result
                                funding cuts. The reason is simple: too many
                                expectations for that time!
                            </p>

                            <p>
                                We believe that AI has moved forward to a new
                                era. Even though we are still ambitious, we are
                                completely aware of the expectations of AI. The
                                advent of deep neural networks brought hope in
                                the field and revolutionized a plethora of
                                domains (image processing, natural language,
                                etc.)
                            </p>

                            <p>
                                To this end, we offer our knowledge to all the
                                spectrum of the AI world. To achieve that, we
                                combined our research and software engineering
                                skills to cover all the aspects of AI. Our aim
                                is to provide you{' '}
                                <strong>
                                    100% authentic deep learning content
                                </strong>
                                .
                            </p>
                        </Block>
                        <Block
                            className="about-block"
                            direction={Direction.vertical}
                        >
                            <h2>Our mission</h2>
                            <p>
                                We are trying to collect and organize all Deep
                                Learning related information from mathematics
                                and models to programming and software
                                architecture into a single place. To present the
                                latest trends in the field and to help you
                                understand <strong>how</strong> and{' '}
                                <strong>why</strong> Artificial Intelligence is
                                going to play a vital role in humanity’s future.
                            </p>

                            <p>
                                And it’s not about just learning stuff. It’s
                                also about advancing your career in the field
                                and finding real solutions to your business
                                problems.
                            </p>

                            <p>
                                There is one other thing.{' '}
                                <strong>
                                    It’s completely free. No ads, no paywall, no
                                    memberships, nothing. We do not charge for
                                    our blog posts, because we believe in
                                    accessible and open knowledge sharing.
                                </strong>
                            </p>
                            <p>
                                <strong>
                                    We do offer some paid courses and books, but
                                    the majority of the content is already
                                    available for free. They simply exist as a
                                    way to cover hosting and other basic
                                    expenses
                                </strong>
                                .
                            </p>
                        </Block>
                    </Group>

                    <div className="section-text">
                        <h2>Interested in contributing to AI Summer ?</h2>
                    </div>
                    <Block
                        className="about-block"
                        direction={Direction.horizontal}
                    >
                        <div>
                            <p>
                                Contributions are more than welcome as long as
                                they meet the quality standard of our past
                                articles. In our mind,{' '}
                                <strong>
                                    the ideal use of our website would be for
                                    people to collaborate and create
                                    high-quality articles
                                </strong>
                                . In fact, we created our{' '}
                                <a
                                    className="external-link"
                                    href="https://discord.gg/2ezWq3r5hv"
                                >
                                    discord server
                                </a>{' '}
                                for this very reason. So if you want to publish
                                your content, feel free to join our community
                                and we can discuss more. Otherwise, you can
                                contact us via email or on social.
                                <br />
                                <br />
                                Each article will be thoroughly reviewed by us
                                and other members of the community for
                                scientific accuracy, clarity, and writing
                                quality. This process can take weeks or even
                                months. During this period, we will work
                                together to ensure the highest possible quality.
                            </p>
                            <p>We generally look for two types of articles:</p>
                            <p>
                                1) Comprehensive overviews of SOTA algorithms,
                                methodologies, or architectures. Some examples
                                include{' '}
                                <Link to="/transformers-computer-vision/">
                                    Transformers in computer vision
                                </Link>
                                ,{' '}
                                <Link to="/deep-learning-biology-alphafold/">
                                    Deep learning on computational biology and
                                    bioinformatics tutorial
                                </Link>{' '}
                                and{' '}
                                <Link to="/graph-convolutional-networks/">
                                    How Graph Neural Networks (GNN) work:
                                    introduction to graph convolutions from
                                    scratch
                                </Link>
                                <p>
                                    2) Thorough and complete tutorials on
                                    libraries or frameworks. Examples include{' '}
                                    <Link to="/hugging-face-vit/">
                                        A complete Hugging Face tutorial: how to
                                        build and train a vision transformer
                                    </Link>
                                    ,{' '}
                                    <Link to="/jax-transformer/">
                                        Build a Transformer in JAX from scratch:
                                        how to write and train your own models
                                    </Link>
                                </p>
                            </p>
                        </div>
                    </Block>

                    <div className="section-text">
                        <h2>Our team</h2>
                    </div>
                    <Group size={2}>
                        <Block
                            className="about-author-block"
                            direction={Direction.vertical}
                        >
                            <h3>{data.authorsYaml.authors[0].name}</h3>
                            <Avatar image={data.authorsYaml.authors[0].image} />
                            <div className="about-author-block__socials">
                                <a
                                    href="https://github.com/SergiosKar"
                                    className="about-author-block__socials__item"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="#333"
                                        viewBox="0 0 24 24"
                                    >
                                        {/* eslint-disable-next-line max-len */}
                                        <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                                    </svg>
                                </a>
                                <a
                                    href="https://twitter.com/karsergios"
                                    className="about-author-block__socials__item"
                                >
                                   <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="24" height="24">
                                        <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"/>
                                    </svg>
                                </a>
                                <a
                                    href="https://www.linkedin.com/in/sergios-karagiannakos/"
                                    className="about-author-block__socials__item"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="#007bb5"
                                        viewBox="0 0 24 24"
                                    >
                                        {/* eslint-disable-next-line max-len */}
                                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                                    </svg>
                                </a>
                            </div>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        data.authorsYaml.authors[0]
                                            .short_description || ''
                                }}
                            ></div>
                            <Link
                                className="about_team__learn-more"
                                to={`/${data.authorsYaml.authors[0].url}`}
                            >
                                Learn more
                            </Link>
                        </Block>
                        <Block
                            className="about-author-block"
                            direction={Direction.vertical}
                        >
                            <h3>{data.authorsYaml.authors[1].name}</h3>
                            <Avatar image={data.authorsYaml.authors[1].image} />
                            <div className="about-author-block__socials">
                                <a
                                    href="https://github.com/black0017"
                                    className="about-author-block__socials__item"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="#333"
                                        viewBox="0 0 24 24"
                                    >
                                        {/* eslint-disable-next-line max-len */}
                                        <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                                    </svg>
                                </a>
                                <a
                                    href="https://twitter.com/nadaloglou"
                                    className="about-author-block__socials__item"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="24" height="24">
                                        <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"/>
                                    </svg>
                                </a>
                                <a
                                    href="https://www.linkedin.com/in/adaloglou17/"
                                    className="about-author-block__socials__item"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="#007bb5"
                                        viewBox="0 0 24 24"
                                    >
                                        {/* eslint-disable-next-line max-len */}
                                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                                    </svg>
                                </a>
                            </div>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        data.authorsYaml.authors[1]
                                            .short_description || ''
                                }}
                            ></div>
                            <Link
                                className="about_team__learn-more"
                                to={`/${data.authorsYaml.authors[1].url}`}
                            >
                                Learn more
                            </Link>
                        </Block>
                    </Group>
                </Container>
            </PageSection>

            <PageSection className="faq-section">
                <Container>
                    <h2>FAQ</h2>
                    <Accordion items={data.faqYaml.faq} />
                </Container>
            </PageSection>
        </PageLayout>
    );
};

export const aboutQuery = graphql`
    {
        authorsYaml {
            authors {
                name
                linkedin
                twitter
                website
                image {
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            base64
                            aspectRatio
                            src
                            srcSet
                            sizes
                        }
                    }
                }
                facebook
                github
                short_description
                url
            }
        }
        file(relativePath: { eq: "logo-initials.png" }) {
            childImageSharp {
                fluid {
                    aspectRatio
                    src
                    srcSet
                    sizes
                }
            }
        }

        faqYaml {
            faq {
                question
                answer
            }
        }
    }
`;

export default About;
